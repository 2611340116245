<template>
  <RPPDropdown
    :model-value="rows"
    :options="rowsOptions"
    option-label="label"
    option-value="value"
    @update:modelValue="onChange($event)"
  />
</template>

<script>
import Dropdown from '../base-dropdown/BaseDropdown';

export default {
    components: {
        'RPPDropdown': Dropdown
    },
    inheritAttrs: false,
    props: {
        options: Array,
        rows: Number
    },
    emits: ['rows-change'],
    computed: {
        rowsOptions() {
            let opts = [];
            if (this.options) {
                for(let i= 0; i < this.options.length; i++) {
                    opts.push({label: String(this.options[i]), value: this.options[i]})
                }
            }
            return opts;
        }
    },
    methods: {
        onChange(value) {
            this.$emit('rows-change', value);
        }
    }
}
</script>
