<template>
  <button
    v-ripple
    :class="containerClass"
    type="button"
  >
    <span class="p-paginator-icon pi pi-angle-double-right" />
  </button>
</template>

<script>
import Ripple from '../ripple/Ripple';

export default {
    directives: {
        'ripple': Ripple
    },
    computed: {
        containerClass() {
            return ['p-paginator-last p-paginator-element p-link', {
                'p-disabled': this.$attrs.disabled
            }];
        }
    }
}
</script>