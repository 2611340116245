<template>
  <li
    v-for="pageLink of value"
    :key="pageLink"
    v-ripple
    @click="onPageLinkClick($event, pageLink)"
  >
    <a
      href=""
      class="pagination__link"
      :class="{'pagination__link--active': ((pageLink - 1) === page)}"
    >{{ pageLink }}</a>
  </li>
</template>
<script>
import Ripple from '../ripple/Ripple';

export default {
    directives: {
        'ripple': Ripple
    },
    inheritAttrs: false,
    props: {
        value: Array,
        page: Number
    },
    emits: ['click'],
    methods: {
        onPageLinkClick(event, pageLink) {
            this.$emit('click', {
                originalEvent: event,
                value: pageLink
            });
        }
    }
}
</script>