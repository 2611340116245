<template>
  <li
    v-ripple
    :class="containerClass"
  >
    <a
      class="pagination__link"
      href=""
    >
      <i class="ri-arrow-left-s-fill" />
    </a>
  </li>
</template>

<script>
import Ripple from '../ripple/Ripple';

export default {
    directives: {
        'ripple': Ripple
    },
    computed: {
        containerClass() {
            return [{
                'p-disabled': this.$attrs.disabled
            }];
        }
    }
}
</script>