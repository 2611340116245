<template>
  <div class="intro-x dropdown">
    <div
      class="dropdown-toggle cursor-pointer"
      @click="toggleDropdown"
    >
      <slot name="toggle" />
    </div>
    <div
      v-if="showDropdown"
      v-clickaway="toggleDropdown"
      class="dropdown-box top-1"
      :class="{ show: showDropdown }"
    >
      <div
        class="dropdown-box__content box bg-white dark:bg-dark-6"
      >
        <div class="notification-content__title">
          <slot name="title" />
        </div>
        <div
          class="dropdown-box__content box bg-white dark:bg-dark-6"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from '../ripple/Ripple';

import { reactive, computed } from "vue";
import { useStore } from "vuex";
import clickaway from "@/directives/clickaway";

export default {
  name: "Notifications",
  directives: { clickaway, Ripple },
  setup() {
    const store = useStore();
    const state = reactive({
      showDropdown: false,
      notifications: []
    });
    store.dispatch("profile/getNotifications");

    function toggleDropdown() {
      state.showDropdown = !state.showDropdown;
    }

    function openDropdown() {
      state.showDropdown = true;
    }

    return {
      toggleDropdown,
      openDropdown,
      showDropdown: computed(() => state.showDropdown),
      notifications: computed(() => state.notifications)
    };
  }
};
</script>
